import {
  DropdownOption,
  InvestorEntityDropdownOption,
  MultiInvestorPickerOption,
  useMonitoringGetInvestorsAndInvestorEntitiesMutation,
} from 'interfaces/graphql.types';
import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { OptionType } from 'renderer';

const enabledRoutes = [
  '/dashboard',
  '/offering/[id]/investments',
  '/documents',
  '/tax-center',
];

const multiInvestorEnabledRoutes = ['/dashboard', '/documents', '/tax-center'];

const useInvestorsAndInvestorEntitiesPickerUtil = () => {
  const {
    state: { loggedIn },
  } = useAuth();

  const router = useRouter();

  const [investor, setInvestor] = useState<MultiInvestorPickerOption>();
  const [investorPickerData, setInvestorPickerData] = useState<
    MultiInvestorPickerOption[]
  >([]);
  const [multiInvestorAvailable, setMultiInvestorAvailable] = useState(false);
  const [multiInvestorVisible, setMultiInvestorVisible] = useState(false);
  const [multiInvestorEnabled, setMultiInvestorEnabled] = useState(false);
  const [investorEntity, setInvestorEntity] = useState<DropdownOption>();
  const [investorEntitiesPickerData, setInvestorEntitiesPickerData] = useState<
    InvestorEntityDropdownOption[] | null | undefined
  >();

  const [
    getInvestorsAndInvestorEntities,
    { loading: loadingInvestorsAndInvestorEntities },
  ] = useMonitoringGetInvestorsAndInvestorEntitiesMutation();

  const isRoute = enabledRoutes.includes(router.pathname);
  const isMultiInvestorEnabledRoute = multiInvestorEnabledRoutes.includes(
    router.pathname
  );

  useEffect(() => {
    if (loggedIn && isRoute) {
      getInvestorsAndInvestorEntities()
        .then((result) => {
          const investors =
            result.data?.monitoringGetInvestorsAndInvestorEntities.investors;

          setInvestorPickerData(investors || []);

          if (investors) {
            const available = investors.length > 1;
            const visible = available && isRoute;
            const enabled = visible && isMultiInvestorEnabledRoute;

            setMultiInvestorAvailable(available);
            setMultiInvestorVisible(visible);
            setMultiInvestorEnabled(enabled);

            const newInvestorPicked = investors[0];

            if (newInvestorPicked) {
              setInvestor(newInvestorPicked);
              setInvestorEntitiesPickerData(newInvestorPicked.investorEntities);

              const newInvestorEntityPicked =
                newInvestorPicked.investorEntities?.[0];

              if (newInvestorEntityPicked)
                setInvestorEntity({
                  __typename: 'DropdownOption',
                  name: newInvestorEntityPicked.name,
                  pk: newInvestorEntityPicked.pk.join(','),
                });
            }
          }
        })
        .catch(console.error);
    }
  }, [loggedIn, isRoute]);

  useEffect(() => {
    const available = investorPickerData.length > 1;
    const visible = available && isRoute;
    const enabled = visible && isMultiInvestorEnabledRoute;
    setMultiInvestorAvailable(available);
    setMultiInvestorVisible(visible);
    setMultiInvestorEnabled(enabled);
  }, [isRoute, isMultiInvestorEnabledRoute]);

  const onSelectChangeInvestorEntity = (
    investorEntityOption?: OptionType | null | undefined
  ) => {
    if (investorEntityOption) {
      setInvestorEntity({
        __typename: 'DropdownOption',
        name: investorEntityOption.label,
        pk: investorEntityOption.value || '',
      });
    }
  };

  const onSelectChangeInvestor = (investorPk: string) => {
    const foundInvestor = investorPickerData.find(
      (inv) => inv.pk === investorPk
    );

    if (foundInvestor) {
      setInvestor(foundInvestor);
      setInvestorEntitiesPickerData(foundInvestor.investorEntities);

      const newInvestorEntityPicked = foundInvestor.investorEntities[0];

      if (newInvestorEntityPicked) {
        setInvestorEntity({
          __typename: 'DropdownOption',
          name: newInvestorEntityPicked.name,
          pk: newInvestorEntityPicked.pk.join(','),
        });
      }
    }
  };

  return {
    investor,
    investorPickerData,
    multiInvestorAvailable,
    multiInvestorVisible,
    multiInvestorEnabled,
    investorEntity,
    investorEntitiesPickerData,
    loadingInvestorsAndInvestorEntities,
    setInvestorEntity,
    setInvestorEntitiesPickerData,
    onSelectChangeInvestorEntity,
    onSelectChangeInvestor,
  };
};

export default useInvestorsAndInvestorEntitiesPickerUtil;
