import {
  DropdownOption,
  InvestorEntityDropdownOption,
  MultiInvestorPickerOption,
} from 'interfaces/graphql.types';
import useInvestorsAndInvestorEntitiesPickerUtil from 'modules/dashboard/utils/useInvestorsAndInvestorEntitiesPicker';
import { createContext, useContext } from 'react';
import { OptionType } from 'renderer';

export const InvestorsAndInvestorEntitiesPickerContext = createContext<{
  investor: MultiInvestorPickerOption | undefined;
  investorPickerData: MultiInvestorPickerOption[];
  multiInvestorAvailable: boolean;
  multiInvestorVisible: boolean;
  multiInvestorEnabled: boolean;
  investorEntity: DropdownOption | undefined;
  investorEntitiesPickerData: InvestorEntityDropdownOption[] | null | undefined;
  loadingInvestorsAndInvestorEntities: boolean;
  onSelectChangeInvestorEntity: (
    investorEntityOption?: OptionType | null | undefined
  ) => void;
  onSelectChangeInvestor: (investorPk: string) => void;
}>({
  investor: undefined,
  investorPickerData: [],
  multiInvestorAvailable: false,
  multiInvestorEnabled: false,
  multiInvestorVisible: false,
  investorEntity: undefined,
  investorEntitiesPickerData: undefined,
  loadingInvestorsAndInvestorEntities: false,
  onSelectChangeInvestorEntity: () => null,
  onSelectChangeInvestor: () => null,
});

const InvestorsAndInvestorEntitiesPickerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    investor,
    investorPickerData,
    multiInvestorAvailable,
    multiInvestorVisible,
    multiInvestorEnabled,
    investorEntity,
    investorEntitiesPickerData,
    loadingInvestorsAndInvestorEntities,
    onSelectChangeInvestorEntity,
    onSelectChangeInvestor,
  } = useInvestorsAndInvestorEntitiesPickerUtil();

  return (
    <InvestorsAndInvestorEntitiesPickerContext.Provider
      value={{
        investor,
        investorPickerData,
        multiInvestorAvailable,
        multiInvestorVisible,
        multiInvestorEnabled,
        investorEntity,
        investorEntitiesPickerData,
        loadingInvestorsAndInvestorEntities,
        onSelectChangeInvestorEntity,
        onSelectChangeInvestor,
      }}
    >
      {children}
    </InvestorsAndInvestorEntitiesPickerContext.Provider>
  );
};

export const useInvestorsAndInvestorEntitiesPicker = () =>
  useContext(InvestorsAndInvestorEntitiesPickerContext);

export default InvestorsAndInvestorEntitiesPickerProvider;
