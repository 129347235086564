import 'react-phone-input-2/lib/style.css';
import 'yet-another-react-lightbox/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../stories/theme/fonts.css';
import 'ui/theme/style.css';

import { ApolloProvider } from '@apollo/client';
import { RECAPTCHA_SITE_KEY } from 'config';
import { OfferingProvider } from 'modules/admin/providers/OfferingProvider';
import AuthProvider from 'modules/auth/providers/AuthProvider';
import NavBarProvider from 'modules/nav/provider/NavBarProvider';
import ClientProvider from 'modules/settings/providers/ClientProvider';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import InvDatePickerProvider from 'providers/InvDatePickerProvider';
import InvestorsAndInvestorEntitiesPickerProvider from 'providers/InvestorsAndInvestorEntitiesPickerProvider';
import InvPageHeaderMenuProvider from 'providers/InvPageHeaderMenuProvider';
import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import theme from 'stories/theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'ui/theme/global-style';
import { useApollo } from 'utils/lib/apolloClient';
const MyApp = ({
  Component,
  pageProps,
  userIsLoggedIn,
}: AppProps & { userIsLoggedIn: boolean }) => {
  const apolloClient = useApollo(null, false);
  const router = useRouter();
  const [clientTheme, setClientTheme] = useState(theme);

  useEffect(() => {
    const startProgress = () => {
      NProgress.start();
    };
    const endProgress = () => NProgress.done();

    router.events.on('routeChangeStart', startProgress);
    router.events.on('routeChangeComplete', endProgress);
    router.events.on('routeChangeError', endProgress);
    return () => {
      router.events.off('routeChangeStart', startProgress);
      router.events.off('routeChangeComplete', endProgress);
      router.events.off('routeChangeError', endProgress);
    };
  }, [router]);

  return (
    <>
      <GoogleReCaptchaProvider
        scriptProps={{ async: true, defer: true }}
        reCaptchaKey={RECAPTCHA_SITE_KEY}
        useEnterprise={true}
      >
        <GlobalStyle />
        <ApolloProvider client={apolloClient}>
          <ClientProvider handleClientTheme={setClientTheme}>
            <ThemeProvider theme={clientTheme}>
              <AuthProvider userIsLoggedIn={userIsLoggedIn}>
                <NavBarProvider>
                  <InvPageHeaderMenuProvider>
                    <InvDatePickerProvider>
                      <InvestorsAndInvestorEntitiesPickerProvider>
                        <OfferingProvider>
                          <Component {...pageProps} />
                        </OfferingProvider>
                      </InvestorsAndInvestorEntitiesPickerProvider>
                    </InvDatePickerProvider>
                  </InvPageHeaderMenuProvider>
                </NavBarProvider>
              </AuthProvider>
            </ThemeProvider>
          </ClientProvider>
        </ApolloProvider>
      </GoogleReCaptchaProvider>
    </>
  );
};
MyApp.getInitialProps = async (ctx: any) => {
  let isLoggedIn = false;
  if (typeof window === 'undefined') {
    if (ctx.ctx.req.cookies['rl-access-token']) {
      isLoggedIn = true;
    }
  }
  return { userIsLoggedIn: isLoggedIn };
};

export default MyApp;
