// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  AdminOfferingDetailsResult,
  OtherOpportunitiesType,
} from 'interfaces/graphql.types';
import React, { createContext, useEffect, useState } from 'react';

interface ContextType {
  offering: AdminOfferingDetailsResult;
  savedOffering: AdminOfferingDetailsResult;
  otherOpportunities: OtherOpportunitiesType[];
  selectedRowIds: { [key: number]: boolean };
  isPromptOpen: boolean;
  redirectTo: string;
  isUnsavedOffering: boolean;
  updateOffering: (updatedState: Partial<ContextType>) => void;
}

const OfferingContext = createContext({} as ContextType);

interface Props {
  children: React.ReactNode;
}

const initState = {
  offering: {},
  savedOffering: {},
  otherOpportunities: [],
  selectedRowIds: {},
  isPromptOpen: false,
  redirectTo: '',
  isUnsavedOffering: false,
  updateOffering: () => {},
};

export const OfferingProvider = ({ children }: Props) => {
  const [state, setState] = useState<ContextType>(initState);

  const updateOffering = (updatedState: Partial<ContextType>) => {
    setState({ ...state, ...updatedState });
  };

  const checkUnsavedOffering = () => {
    const { offering, savedOffering } = state;

    const offeringKeysToCompare = Object.keys(offering).filter(
      (key) => typeof offering[key] !== 'object'
    );

    let isUnsaved = false;

    isUnsaved = !offeringKeysToCompare.every(
      (key) =>
        JSON.stringify(offering[key]) === JSON.stringify(savedOffering[key])
    );

    if (isUnsaved) return true;

    const offeringContent = offering.content || {};
    const savedOfferingContent = savedOffering.content || {};

    const contentKeysToCompare = Object.keys(offeringContent);

    isUnsaved = !contentKeysToCompare.every(
      (key) =>
        JSON.stringify(offeringContent[key]) ===
        JSON.stringify(savedOfferingContent[key])
    );

    if (isUnsaved) return true;

    const offeringRequirements = offering.requirements || {};
    const savedOfferingRequirements = savedOffering.requirements || {};

    const requirementsKeysToCompare = Object.keys(offeringRequirements);

    isUnsaved = !requirementsKeysToCompare.every(
      (key) =>
        JSON.stringify(offeringRequirements[key]) ===
        JSON.stringify(savedOfferingRequirements[key])
    );

    if (isUnsaved) return true;

    return false;
  };

  useEffect(() => {
    if (state.offering && state.savedOffering) {
      const isUnsavedOffering = checkUnsavedOffering();
      if (isUnsavedOffering !== state.isUnsavedOffering) {
        updateOffering({ isUnsavedOffering });
      }
    }
  }, [state.offering, state.savedOffering, state.isUnsavedOffering]);

  return (
    <OfferingContext.Provider
      value={{
        ...state,
        updateOffering,
      }}
    >
      {children}
    </OfferingContext.Provider>
  );
};

export const useOffering = () => React.useContext(OfferingContext);
